<template>
  <div class="place-items-center">
    <form class="mt-2" @submit.prevent="handleAdd">
      <div class="place-items-center">
        <h1 class="font-bold text-gray-900 text-xl py-2">Dodajanje grobova</h1>
        <div class="md:flex justify-center space-x-4 mx-4 mb-5">
          <label for="firstname">
            <p class="md:flex md:justify-start mx-2">Ime:</p>
            <input type="text" id="firstname" name="firstname" placeholder="Janez"
            v-model="this.person.firstname"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full placeholder:text-slate-300" />
          </label>
          <label for="lastname">
            <p class="md:flex md:justify-start">Priimek:</p>
            <input type="text" id="lastname" name="lastname" placeholder="Novak"
            v-model="this.person.lastname"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full placeholder:text-slate-300" />
          </label>
        </div>
        <div class="md:flex justify-center space-x-4 mx-4 mb-5">
          <label for="yearBirth">
            <p class="md:flex md:justify-start mx-2">Leto rojstva:</p>
            <input type="number" id="yearBirth" name="yearBirth" placeholder="1950"
            v-model="this.person.yearBirth"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full placeholder:text-slate-300" />
          </label>
          <label for="yearDeath">
            <p class="md:flex md:justify-start">Leto smrti:</p>
            <input type="number" id="yearDeath" name="yearDeath" placeholder="2022"
            v-model="this.person.yearDeath"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full placeholder:text-slate-300" />
          </label>
        </div>
        <div class="md:flex justify-center space-x-4 mx-4 mb-5">
          <label for="location">
            <p class="md:flex md:justify-start mx-2">Izberite lokacijo:</p>
            <select v-model="this.person.location"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-2 w-full bg-white">
              <option disabled value="">Izberite lokacijo</option>
              <option v-for="site in sites" :value="site.name" :key="site.id">{{ site.fullName }}
              </option>
            </select>
          </label>
          <label for="department">
            <p class="md:flex md:justify-start">Oddelek:</p>
            <input type="text" id="department" name="department" placeholder="01"
            v-model="this.person.department"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full placeholder:text-slate-300"/>
          </label>
        </div>
        <div class="md:flex justify-center space-x-4 mx-4 mb-5">
          <label for="section">
            <p class="md:flex md:justify-start mx-2">Odsek:</p>
            <input type="text" id="section" name="section" placeholder="02"
            v-model="this.person.section"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full placeholder:text-slate-300" />
          </label>
          <label for="row">
            <p class="md:flex md:justify-start">Vrsta:</p>
            <input type="text" id="row" name="row" placeholder="03"
            v-model="this.person.row"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full placeholder:text-slate-300" />
          </label>
        </div>
        <div class="md:flex justify-center space-x-4 mx-4 mb-5">
          <label for="number">
            <p class="md:flex md:justify-start">Številka groba:</p>
            <input type="text" id="number" name="number" placeholder="04 ali 05-07"
            v-model="this.person.number"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full placeholder:text-slate-300" />
          </label>
        </div>
        <div class="my-4 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-lime-500 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
          <button type="submit" id="submitButton" name="submitButton"
          class="">
            Dodaj
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

const axios = require('axios');

export default {
  name: 'AddGrave',
  data() {
    return {
      sites: [
        { id: 1, name: 'C', fullName: 'Celjsko pokopališče' },
        { id: 2, name: 'T', fullName: 'Teharsko pokopališče' },
      ],
      person: {
        firstname: '',
        lastname: '',
        yearBirth: '',
        yearDeath: '',
        location: null,
        department: '',
        section: '',
        row: '',
        number: '',
      },
    };
  },
  props: ['token', 'selPerson'],
  methods: {
    async handleAdd() {
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`,
        },
      };
      console.log(this.token);
      console.log(this.person);
      const res = await axios.post('http://app-mo.enmemoro.si/graves/grave', {
        ime: this.person.firstname,
        priimek: this.person.lastname,
        rojstvo: this.person.yearBirth,
        smrt: this.person.yearDeath,
        l_ime: this.person.location,
        l0: this.person.department,
        l1: this.person.section,
        l2: this.person.row,
        l3: this.person.number,
      }, config);
      console.log(res);
    },
  },
  mounted() {
    if(this.selPerson) {
      this.person.location = this.selPerson.l_ime;
      this.person.department = this.selPerson.l0;
      this.person.section = this.selPerson.l1;
      this.person.row = this.selPerson.l2;
      this.person.number = this.selPerson.l3;
    } else {
      this.person.location = '';
      this.person.department = '';
      this.person.section = '';
      this.person.row = '';
      this.person.number = '';
    }
  }
};

</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
