<template>
<div class="rounded-3xl pt-4 mt-4 sm:m-4" id="searchBg">
  <div class="text-black rounded-2xl mx-4">
    <label for="searchName">
        <input type="text" id="searchName" name="searchName" v-model="searchName"
        placeholder="Išči po grobovih..." v-debounce:300ms="filterGraves"
        class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 mt-5 w-full" />
    </label>
  </div>
  <div class="text-white text-base pt-2 m-4 rounded-2xl">
    <div v-for="grave in graves" :key="grave.id"
    class="border rounded-md sm:mx-10 my-3 border-slate-200 hover:border-slate-300 hover:bg-cyan-900
    justify-items-start block sm:flex md:pl-16 text-align-center sm:text-align-left py-1"
    @click="updateSelected(grave)" @keypress="updateSelected(grave)">
      <p class="sm:mr-2 sm:ml-5">{{ grave.ime }} {{ grave.priimek }}</p>
      <p class="sm:ml-5">({{ grave.rojstvo }} - {{ grave.smrt }})</p>
    </div>
    <div class="text-red-200">
      <br /><br />
      <p>
        Konec seznama!
      </p>
    </div>
  </div>
</div>
</template>

<script>

import { vue3Debounce } from 'vue-debounce';

const axios = require('axios');

export default {
  name: 'SearchPage',
  components: {
  },
  directives: {
    debounce: vue3Debounce({ lock: false }),
  },
  data() {
    return {
      graves: '',
      searchName: '',
      selectedPlace: {},
      config: {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    };
  },
  props: ['token'],
  methods: {
    toggleModal() {
      this.show = !this.show;
    },
    toggleEdit() {
      this.edit = !this.edit;
    },
    updateSelected(selectedItem) {
      this.selectedPlace = selectedItem;

      this.$emit('grave', this.selectedPlace.lid);

      let searchParams = new URLSearchParams();

      searchParams.set("g", this.selectedPlace.lid);

      if (window.history.replaceState) {
          const url = window.location.protocol 
                    + "//" + window.location.host 
                    + window.location.pathname 
                    + "?" 
                    + searchParams.toString();

        window.history.replaceState({
            path: url
        }, "", url)
      }
    },
    async filterGraves() {
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`,
        },
        params: {
          name: this.searchName,
        },
      };

      const res = await axios.get('http://app-mo.enmemoro.si/graves/search', config);
      this.graves = res.data;

      const searchInput = document.getElementById("searchName");

      searchInput.addEventListener("keyup", function(event) {
        let searchParams = new URLSearchParams(window.location.search);

        searchParams.set("q", event.target.value);

        if (window.history.replaceState) {
          const url = window.location.protocol 
                    + "//" + window.location.host 
                    + window.location.pathname 
                    + "?" 
                    + searchParams.toString();

        window.history.replaceState({
            path: url
      }, "", url)
    }
});
    },
  },
  mounted() {
      this.searchName = (new URL(location.href).searchParams.get('q'));

      if (this.searchName === null) {
        this.searchName = '';
      }

      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          name: this.searchName,
        },
      };

      axios.get('http://app-mo.enmemoro.si/graves/search', config)
        .then((res) => {
          this.graves = res.data;    
        }).catch((error) => console.log(error));
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: auto;
  height: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: rgb(201, 193, 193);
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-height: 100%;
  overflow-y: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#searchBg {
  background-color: #4B3F23;
}

</style>
