import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: '',
    }
]
export const router = createRouter ({
    history: createWebHistory(),
    routes
})