<template>
  <LoginPage v-if="!loginOK" @successful="logedIn" class="py-6 mt-8 md:mt-10"/>
  <NavBar v-if="loginOK" @search="switchToSearch" @add="switchToAdd" @logout="handleLogout" :currentPage="this.curPage"/>
  <hr />
  <SearchPage v-if="curPage === 'search' && loginOK" :current="search" :token="this.token" @grave="handleGrave" @searchPerson="handleSearchPerson"/>

  <AddGrave v-if="curPage === 'add' && loginOK" :current="add" :token="this.token" :selPerson="this.selPerson" />

  <GraveId v-if="curPage === 'grave' && loginOK" :token="this.token" :lid="this.graveId" @back="handleBack" @person="handlePerson" @addExisting="handleAddExisting"/>

  <PersonPage v-if="curPage === 'person' && loginOK" :token="this.token" :selectedPerson="this.selectedPerson" @backToGrave="handleBackToGrave" />
</template>

<script>
import LoginPage from './components/Login.vue';

import NavBar from './components/NavBar.vue';

import SearchPage from './components/Search.vue';

import AddGrave from './components/AddGrave.vue';

import GraveId from './components/GraveId.vue';

import PersonPage from './components/Person.vue';

export default {
  name: 'App',
  components: {
    LoginPage,
    NavBar,
    SearchPage,
    AddGrave,
    GraveId,
    PersonPage,
},
  data() {
    return {
      curPage: '',
      token: '',
      loginOK: false,
      graveId: '',
      selectedPerson: '',
      selPerson: '',
    };
  },
  methods: {
    switchToSearch() {
      this.curPage = 'search';
    },
    switchToAdd() {
      this.curPage = 'add';
    },
    handleLogout() {
      this.curPage = '';
      this.token = '';
      this.graveId = '';
      this.selectedPerson = '';
      this.loginOK = false;
      this.$router.replace('/');
    },
    logedIn(value) {
      this.token = value;
      this.graveId = '';
      this.loginOK = true;
      this.graveId = (new URL(location.href).searchParams.get('g'));

      if (this.graveId === null) {
        this.graveId = '';
        this.curPage = 'search';
      } else {
        this.curPage = 'grave';
      }
    },
    handleGrave(value) {
      this.graveId = value;
      this.curPage = 'grave';
    },
    handleBack() {
      this.curPage = 'search';
    },
    handlePerson(value) {
      this.selectedPerson = value;
      this.curPage = 'person';
    },
    handleBackToGrave(value) {
      this.graveId = value;
      this.curPage = 'grave';
    },
    handleAddExisting(value) {
      this.selPerson = value;
      this.curPage = 'add';
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>