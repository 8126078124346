<template>
  <div class="place-items-center">
    <img src="../../public/img/enmemoro-logo.png" class="m-auto mb-10 mt-6">
    <form @submit.prevent="handleLogin" class="mt-2">
      <label for="gravesite" class="block text-base font-base text-black-500">Izberite lokacijo
        <div class="mt-1 realtive rounded-md shadow-sm mb-2">
          <select id="gravesite" name="gravesite" ref="gravesite"
          class="rounded-md bg-white border border-gray-600 pt-1">
            <option value="Celje">Celje</option>
            <option value="Velenje">Velenje</option>
            <option value="Trbovlje">Trbovlje</option>
            <option value="Zagorje">Zagorje</option>
            <option value="Žalec">Žalec</option>
          </select>
        </div>
      </label>
      <label for="username" class="block text-base font-base text-black-500">Uporabniško ime
        <div class="mt-1 realtive rounded-md shadow-sm">
          <input type="text" name="username" id="username" ref="username"
          class="foucus:ring-blue-500 hover:ring-blue-300 block rounded-md border border-gray-600 text-base
          m-auto mb-2 p-1"
          placeholder="Uporabniško ime"/>
        </div>
      </label>
      <label for="password" class="block text-base font-base text-black-500">Geslo
        <div class="mt-1 realtive rounded-md shadow-sm">
          <input type="password" name="password" ref="password"
          class="foucus:ring-blue-500 hover:ring-blue-300 block rounded-md border border-gray-600 text-base
          m-auto mb-4 p-1"
          placeholder="Geslo"/>
        </div>
      </label>
      <div v-if="wrongCreds" class="text-red-500">
        Napačno uporabniško ime ali geslo!
      </div>
      <div class="mb-4 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-black font-medium sm:ml-3 sm:w-auto sm:text-sm">
        <button type="submit" id="submit" class="pt-1">Prijava</button>
      </div>
    </form>
  </div>
  <footer class="mt-60 m-4 inset-x-0 md:m-auto md:mt-16 md:w-1/2">
    <img src="../../public/img/vavcer-logo.png">
    <div class="block mt-2 md:font-base" id="footer">
      <p class="text-slate-500">
        Pridobili smo sredstva za operacijo »Vavčer za digitalni marketing«. Naložbo izvedbe spletne strani, spletne trgovine in mobilne aplikacije sofinancirata Republika Slovenija in Evropska unija iz Evropskega sklada za regionalni razvoj.
      </p>
      <p class="text-slate-500">
        EnMemoro 2022 © Vse pravice pridržane<br />
        <a href="https://enmemoro.store/splosni-pogoji/">Splošni pogoji</a> | 
        <a href="https://enmemoro.store/splosni-pogoji/">Politika zasebnosti</a>
      </p>
    </div>
  </footer>
</template>

<script>

const axios = require('axios');

export default {
  name: 'LoginPage',
  data() {
    return {
      showPassword: false,
      wrongCreds: false,
    };
  },
  methods: {
    async handleLogin() {
      const data = {
        email: this.$refs.username.value,
        password: this.$refs.password.value,
        gravesite: this.$refs.gravesite.value,
      };

      await axios.post('http://app-mo.enmemoro.si/auth/login', {
        email: data.email,
        password: data.password,
        gravesite: data.gravesite,
      }
      ).then((result) => {
        const token = result.data.access_token;
        this.$emit('successful', token);
      }).catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else if (error.message) {
          console.log(error.message);
        }
        this.wrongCreds = true;
        this.$refs.password.value = '';
      });
    },
  },
};
</script>

<style scoped>
div, label, input {
  max-width: 100px 0 0;
  align-items: center;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#footer {
  font-size: 0.6rem;
}
</style>
