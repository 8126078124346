<template>
  <div class="pl-4 pt-10 justify-items-start text-black">
    <ArrowLeftIcon class="block h-6 w-6 mr-1" aria-hidden="true" @click="handleBack" />
  </div>
  <form @submit.prevent="handleEdit" class=" text-black py-2">
    <div class="text-black place-items-center">
      <div class="md:flex md:justify-center mx-4 space-x-6">
        <label for="firstname">
          <p class="md:flex md:justify-start">Ime:</p>
            <input type="text"
            id="firstname" name="firstname" v-model="this.grave.ime"
            :disabled="!edit"
            class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full">
        </label>
        <label for="lastname">
          <p class="md:flex md:justify-start">Priimek:</p>
          <input type="text"
          id="lastname" name="lastname"
          :disabled="!edit" v-model="this.grave.priimek"
          class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full mb-5">
        </label>
      </div>
      <div class="md:flex md:justify-center mx-4 space-x-6">
        <label for="yearBirth">
          <p class="md:flex md:justify-start">Leto rojstva:</p>
          <input type="text"
          id="yearBirth" name="yearBirth"
          :disabled="!edit" v-model="this.grave.rojstvo"
          class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full">
        </label>
        <label for="yearDeath">
          <p class="md:flex md:justify-start">Leto smrti:</p>
          <input type="text"
          id="yearDeath" name="yearDeath"
          :disabled="!edit" v-model="this.grave.smrt"
          class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full mb-5">
        </label>
      </div>
      <div class="md:flex md:justify-center mx-4 space-x-6">
        <label for="location">
          <p class="md:flex md:justify-start">Lokacija:</p>
          <input type="text"
          id="location" name="location"
          :disabled="!edit" v-model="this.grave.l_ime"
          class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full">
        </label>
        <label for="department">
          <p class="md:flex md:justify-start">Oddelek:</p>
          <input type="text"
          id="department" name="department"
          :disabled="!edit" v-model="this.grave.l0"
          class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full mb-5">
        </label>
      </div>
      <div class="md:flex md:justify-center mx-4 space-x-6">
        <label for="section">
          <p class="md:flex md:justify-start">Odsek:</p>
          <input type="text"
          id="section" name="section"
          :disabled="!edit" v-model="this.grave.l1"
          class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full">
        </label>
        <label for="row">
          <p class="md:flex md:justify-start">Vrsta:</p>
          <input type="text"
          id="row" name="row"
          :disabled="!edit" v-model="this.grave.l2"
          class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full mb-5">
        </label>
      </div>
      <div class="md:flex md:justify-center mx-4 space-x-6">
        <label for="number">
          <p class="md:flex md:justify-start">Številka groba:</p>
          <input type="text"
          id="number" name="number"
          :disabled="!edit" v-model="this.grave.l3"
          class="border rounded-md border-gray-900 hover:border-gray-700 px-1 py-1 w-full">
        </label>
      </div>
      <button class="rounded-md border border-gray-400 hover:bg-gray-100 pt-1 px-1 mt-4
      text-black" v-if="edit" type="submit" id="submit" @click="handleBack"
      >Spremeni</button>
    </div>
  </form>
  <div class="text-black py-4">
    <button @click="toggleEdit" v-if="!edit">
      <PencilIcon class="block h-6 w-6 mr-1" aria-hidden="true" />
    </button>
  </div>
</template>

<script>

import { ArrowLeftIcon, PencilIcon } from'@heroicons/vue/outline';

const axios = require('axios');

export default {
  name: 'PersonPage',
  props: ['token', 'selectedPerson'],
  data() {
    return {
      grave: 
        {
          ime: '',
          priimek: '',
          rojstvo: '',
          smrt: '',
          l_ime: '',
          l0: '',
          l1: '',
          l2: '',
          l3: '',
          res_grob: '',
        },
      edit: false,
    }
  },
  components: {
    ArrowLeftIcon,
    PencilIcon,
  },
  methods: {
    async handleEdit() {
      const data = {
        firstname: this.grave.ime,
        lastname: this.grave.priimek,
        yearBirth: this.grave.rojstvo,
        yearDeath: this.grave.smrt,
        location: this.grave.l_ime,
        department: this.grave.l0,
        section: this.grave.l1,
        row: this.grave.l2,
        number: this.grave.l3,
      };
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}`,
        },
      };
      const res = await axios.put(`http://app-mo.enmemoro.si/graves/grave/${this.grave.id}`, {
        ime: data.firstname,
        priimek: data.lastname,
        rojstvo: data.yearBirth,
        smrt: data.yearDeath,
        l_ime: data.location,
        l0: data.department,
        l1: data.section,
        l2: data.row,
        l3: data.number,
      }, config);
      console.log(res);
    },
    handleBack() {
      this.$emit('backToGrave', this.selectedPerson.lid);
      let searchParams = new URLSearchParams();

      searchParams.append("g", this.selectedPerson.lid);

      if (window.history.replaceState) {
          const url = window.location.protocol 
                    + "//" + window.location.host 
                    + window.location.pathname 
                    + "?" 
                    + searchParams.toString();

        window.history.replaceState({
            path: url
        }, "", url)
      }
    },
    toggleEdit() {
      this.edit = !this.edit;
    }
  },
  mounted() {
    this.grave = this.selectedPerson;
  }
}

</script>