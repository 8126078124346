<template>
  <Disclosure as="nav" class="sticky top-0" v-slot="{ open }" style="background-image: url('https://kiosk.enmemoro.eu/assets/images/background_full.jpg');">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md
          text-gray-900 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2
          focus:ring-inset focus:ring-white">
            <span class="sr-only">Odpri meni</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <h1 class="text-3xl font-bold text-black pl-8 pt-2">
              Enmemoro
            </h1>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <a v-for="item in navigation" :key="item.name" @click="$emit(item.tag)"
              @keypress="$emit(item.tag)"
              :class="[item.current ? 'bg-gray-900 text-white' :
              'text-slate-900 hover:bg-gray-700 hover:text-white',
              'px-3 py-2 rounded-md text-sm font-medium']"
              >{{ item.name }}</a>
            </div>
          </div>
         </div>
        <div class="right-0 block text-black font-semibold">
          <button type="button" id="logout" name="logout" class="
          rounded-md px-2 py-1 hover:bg-cyan-800 hover:text-white flex" @click="toggleModal">
            <LogoutIcon class="block h-6 w-6" aria-hidden="true" />
            <div class="hidden sm:flex">Odjava</div>
          </button>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden sticky top-0">
      <div class="flex px-2 mx-2 pt-2 pb-3 space-y-1">
        <DisclosureButton v-for="item in navigation" :key="item.name" as="a"
        @click="$emit(item.tag)" @keypress="$emit(item.tag)"
        :class="[item.current ? 'bg-gray-900 text-white' :
        'text-gray-900 hover:bg-gray-700 hover:text-white',
        'w-1/2 block px-3 py-2 rounded-md text-base font-medium']"
        >{{ item.name }}</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
  <!-- Logout modal window -->
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="pt-4 pb-2 bg-teal-600 text-white rounded-t">
            <h2>Pozor!</h2>
          </div>

          <div class="my-3 pt-2 text-black">
            <h4>Ali se res želite odjaviti?</h4>
          </div>

          <div class="grid grid-cols-2 gap-8 px-2 py-2 text-black">
            <span class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="toggleModal">
              <button class="pt-1">Prekliči</button>
            </span>
            <span class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-600 text-base font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            @click="handleLogout">
              <button class="pt-1">Da</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </Transition>

</template>

<script>

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

import { MenuIcon, XIcon, LogoutIcon } from '@heroicons/vue/outline';

export default {
  name: 'NavBar',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
    LogoutIcon,
  },
  data() {
    return {
      navigation: [
        { name: 'Išči', tag: 'search', current: true },
        { name: 'Dodaj grob', tag: 'add', current: false },
      ],
      show: false,
    };
  },
  props: ['currentPage'],
  updated() {
    if (this.currentPage === this.navigation[0].tag) {
      this.navigation[0].current = true;
      this.navigation[1].current = false;
    }
    if (this.currentPage === this.navigation[1].tag) {
      this.navigation[0].current = false;
      this.navigation[1].current = true;
    }
  },
  methods: {
    toggleModal() {
      this.show = !this.show;
    },
    handleLogout() {
      this.$emit('logout');
    },
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 95%;
  background-color: #fff;
  margin: 0px auto;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}


.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>