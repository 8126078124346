<template>
  <div class="text-black">
    <div class="pl-4 pt-8 sm:pt-10 justify-items-start">
      <ArrowLeftIcon class="block h-6 w-6 mr-1" aria-hidden="true" @click="handleBack" />
    </div>
    <br />
    <div class="space-y-2 pb-16">
      <div v-if="!picEdit" class="relative">
        <img :src="`http://celje.enmemoro.si/images/grobovi/1/${selectedPlace[0].res_grob}`" class="h-auto w-full object-cover rounded-md md:h-full md:w-1/2 md:px-4">
        <PencilIcon class="block absolute top-0 h-6 w-6 justify-self-center text-white m-3 md:mx-7" @click="togglePicEdit" aria-hidden="true"/>
      </div>
      <div v-if="picEdit" class="relative">
        <UploadMedia>

        </UploadMedia>

        <XIcon class="block absolute top-0 h-6 w-6 justify-self-center text-black m-3" @click="togglePicEdit" aria-hidden="true"/>
      </div>



      <div class="grid grid-cols-2 md:block md:justify-items-start sm:space-x-4 px-4 pt-2">
        <button type="button" :class="[curView === 'per' ? 'bg-gray-900 text-white' :
              'text-slate-900 hover:bg-gray-700 hover:text-white',
              'px-3 py-2 rounded-md text-sm font-medium']" @click="switchToPeople">
          Pokopane osebe
        </button>
        <button type="button" :class="[curView === 'loc' ? 'bg-gray-900 text-white' :
              'text-slate-900 hover:bg-gray-700 hover:text-white',
              'px-3 py-2 rounded-md text-sm font-medium']" @click="switchToLocation">
          Lokacija
        </button>
      </div>

      <div v-if="curView === 'per'">
        <h2 class="flex pl-4 pt-6">
          Pokopani v tem grobu:
        </h2>
        <div v-for="person in selectedPlace" :key="person.id"
        class="rounded-md mx-4 my-3 py-1 bg-white hover:shadow-2xl
        justify-items-start block sm:flex md:pl-16 text-align-center sm:text-align-left"
        @click="updateSelected(person)" @keypress="updateSelected(person)">
          <p class="sm:mr-2 sm:ml-5">{{ person.ime }} {{ person.priimek }}</p>
          <p class="sm:ml-5">({{ person.rojstvo }} - {{ person.smrt }})</p>
        </div>
        <div
        class="rounded-md mx-4 my-6 py-1 bg-white hover:shadow-2xl
        justify-items-start block sm:flex md:pl-16 text-align-center sm:text-align-left"
        @click="addToExistingLoc">
          <p class="text-blue-400">Dodaj novo osebo na to lokacijo</p>
        </div>
      </div>

      <div v-if="curView === 'loc'">

        <div class="grid justify-items-start px-4">
          <h2 class="justify-self-start font-semibold">
            Informacije o grobu
          </h2>
        </div>
        <div class="grid justify-items-start sm:flex sm:space-x-4 px-4 mb-3">
          <p calss="justify-self-start">Lokacija: {{ selectedPlace[0].l_ime }}</p>
          <p>Oddelek: {{ selectedPlace[0].l0 }}</p>
          <p>Odsek: {{ selectedPlace[0].l1 }}</p>
          <p>Vrsta: {{ selectedPlace[0].l2 }}</p>
          <p>Številka groba: {{ selectedPlace[0].l3 }}</p>
        </div>
        <div class="hidden"> 
          scale: {{ scale.toFixed(2) }} <br />
          origin: ({{ originX.toFixed(2) }}, {{ originY.toFixed(2) }}) <br />
          translate: ({{ translateX.toFixed(2) }}, {{ translateY.toFixed(2) }}) <br />
          TopLeft: ({{ topLeft.x }} , {{ topLeft.y }}) <br/>
          BottomRight: ({{ botRight.x }} , {{ botRight.y }}) <br/>
        </div>

        <div class="relative inline-block" width="350" height="350">
          <PinchScrollZoom
            ref="zoomer"
            :width="350"
            :height="350"
            :scale="scale"
            :translate-x="translateX"
            :translate-y="translateY"
            :origin-x="originX"
            :origin-y="originY"
            :within="within"
            :min-scale="minScale"
            :max-scale="maxScale"
            @scaling="reset"
            @startDrag="(e) => onEvent('startDrag', e)"
            @stopDrag="(e) => onEvent('stopDrag', e)"
            @dragging="(e) => onEvent('dragging', e)"
            style="border: 1px solid black"
            :content-width="500"
            :content-height="500"
            class="m-auto"
            id="pinchzoom"
          >
            <img src="../../public/img/celje_location.png" width="500" height="500"/>
        </PinchScrollZoom>
          <LocationMarkerIcon class="absolute text-red-500 h-6 w-5 m-auto top-38 left-42" />
        

        </div>

        <br/>
        <div class="my-4 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-lime-500 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
          <button @click="onLocationSelection">Izberi</button><br />
        </div>
        <div class="hidden">
          <button @click="reset">Reset</button><br />
          <label>
            <input type="checkbox" v-model="within" :value="true" />
            within
          </label>
          <br />
          <label>
            min scale:
            <input type="number" v-model.number="minScale" style="width: 40px" />
          </label>
          <label>
            max scale:
            <input type="number" v-model.number="maxScale" style="width: 40px" />
          </label>
          <p>
            {{ eventName }}:<br />
            {{ eventData }}
          </p>
        </div>
      </div>

    </div>
    <div class=" pb-10 grid text-red-500" @click="toggleModal">
      <TrashIcon class="block h-6 w-6 mr-1 justify-self-center" aria-hidden="true"/>
    </div>
  </div>
  <!-- Logout modal window -->
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="pt-4 pb-2 bg-red-600 text-white rounded-t">
            <h2>Pozor!</h2>
          </div>

          <div class="my-3 pt-2 px-2 text-black">
            <h4>Ali res želite izbrisati ta grob in pokopane osebe?</h4>
          </div>

          <div class="grid grid-cols-2 gap-8 px-2 py-2 text-black">
            <span class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="toggleModal">
              <button class="pt-1">Prekliči</button>
            </span>
            <span class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            @click="handleDelete">
              <button class="pt-1">Da</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>

import { ArrowLeftIcon, TrashIcon, PencilIcon, XIcon } from '@heroicons/vue/outline';

import { LocationMarkerIcon } from '@heroicons/vue/solid';

import { UploadMedia } from 'vue-media-upload';

import PinchScrollZoom from "@coddicat/vue3-pinch-scroll-zoom";

const axios = require('axios');

export default {
    name: 'GraveId',
    props: ['lid', 'token'],
    components: {
    TrashIcon,
    ArrowLeftIcon,
    PencilIcon,
    XIcon,
    LocationMarkerIcon,
    UploadMedia,
    PinchScrollZoom,
},
    emits: ['person', 'back'],
    data() {
      return {
        selectedPlace: [
          {
            ime: '',
            priimek: '',
            rojstvo: '',
            smrt: '',
            l_ime: '',
            l0: '',
            l1: '',
            l2: '',
            l3: '',
            res_grob: '',
            pot: '',
          }
        ],
        selectedPerson: '',
        show: false,
        curView: 'per',
        picEdit: false,
        within: false,
        minScale: 5,
        maxScale: 5,
        scale: 5,
        eventName: 'N/A',
        eventData: '',
        originX: 0,
        originY: 0,
        translateX: 0,
        translateY: 0,
        topLeft: {
          x: 46.23091,
          y: 15.27931,
        },
        botRight: {
          x: 46.22812,
          y: 15.28762,
        },
        imgHeight: '',
        imgWidth: '',
        path: '/img/celje_location.png',
      }
    },
    mounted() {
      const config = {
          headers: {
            'Authorization': `Bearer ${this.token}`,
          },
      };

      axios.get('http://app-mo.enmemoro.si/graves/grave/' + this.lid, config)
          .then((res) => {
          this.selectedPlace = res.data;    
          const personID = (new URL(location.href).searchParams.get('p'));
          if (personID !== null) {
            for (let i = 0; i < this.selectedPlace.length; i++) {
              if (this.selectedPlace[i].id === personID) {
                this.$emit('person', this.selectedPlace[i]);
              } 
            }
          }
      }).catch((error) => console.log(error));

    },
    methods: {
      handleBack() {
        this.$router.replace('/');
        this.$emit('back');
      },
      updateSelected(selectedItem) {
        this.selectedPerson = selectedItem;

        this.$emit('person', this.selectedPerson);

        let searchParams = new URLSearchParams();

        searchParams.append("g", this.selectedPerson.lid);
        searchParams.append("p", this.selectedPerson.id);

        if (window.history.replaceState) {
          const url = window.location.protocol 
                      + "//" + window.location.host 
                      + window.location.pathname 
                      + "?" 
                      + searchParams.toString();

          window.history.replaceState({
            path: url
          }, "", url)
        } 
      },
      addToExistingLoc() {
        const temp = {
          l_ime: this.selectedPlace[0].l_ime,
          l0: this.selectedPlace[0].l0,
          l1: this.selectedPlace[0].l1,
          l2: this.selectedPlace[0].l2,
          l3: this.selectedPlace[0].l3,
        }

        this.$emit('addExisting', temp);
        this.$router.replace('/');
      },
      toggleModal() {
        this.show = !this.show;
      },
      switchToPeople() {
        this.curView = 'per';
      },
      switchToLocation() {
        this.curView = 'loc';
      },
      togglePicEdit() {
        this.picEdit = !this.picEdit;
      },
      onEvent(name, e) {
      this.eventName = name;
      this.eventData = e;
      this.scale = e.scale;
      this.originX = e.originX;
      this.originY = e.originY;
      this.translateX = e.translateX;
      this.translateY = e.translateY;
    },
    reset() {
      (this.$refs.zoomer).setData({
        scale: 5,
        originX: 0,
        originY: 0,
        translateX: 0,
        translateY: 0        
      });
    },
    onLocationSelection() {
      const image = new Image();
      image.onload;
      image.src = this.path;

      let resizedImage = {
        width: '', height: ''
      };
      resizedImage.width = 348;
      
      for (let i = 0; i < 2; i++) {
        resizedImage.height = parseFloat((348 * image.height) / image.width).toFixed(5);
      }

      this.imgWidth = parseFloat(resizedImage.width * this.scale).toFixed(5);
      this.imgHeight = parseFloat(resizedImage.height * this.scale).toFixed(5);

      let markerPos = {
        x: parseFloat(this.translateX - 175).toFixed(5),
        y: parseFloat(this.translateY - 175).toFixed(5),
      }

      // let a = {x: 0, y: 0};
      let b = {x: -this.imgWidth, y: -this.imgHeight};

      let ratioX = parseFloat( markerPos.x / b.x ).toFixed(5);
      let ratioY = parseFloat( markerPos.y / b.y ).toFixed(5);

      let coordinates = {
        n: parseFloat((( this.botRight.x - this.topLeft.x ) * ratioY ) + this.topLeft.x).toFixed(6),
        e: parseFloat((( this.botRight.y - this.topLeft.y ) * ratioX ) + this.topLeft.y).toFixed(6),
      }      

      console.log('coordinates: ');
      console.log(coordinates);
    },
  },
}

</script>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 95%;
  background-color: #fff;
  margin: 0px auto;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}


.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>